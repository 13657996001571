{ "compressionlevel":-1,
 "editorsettings":
    {
     "export":
        {
         "format":"json",
         "target":"test5.json"
        }
    },
 "height":10,
 "infinite":false,
 "layers":[
        {
         "data":[4, 9, 64, 64, 4, 4, 4, 64, 64, 64, 4, 5, 15, 2, 2, 3, 2, 2, 2, 10, 64, 2, 64, 64, 64, 64, 64, 64, 2, 64, 64, 2, 2, 3, 2, 2, 8, 4, 3, 64, 64, 3, 64, 2, 64, 64, 2, 4, 2, 64, 4, 2, 64, 2, 64, 64, 2, 64, 17, 64, 4, 2, 64, 2, 64, 3, 2, 2, 2, 4, 64, 2, 64, 2, 64, 2, 64, 64, 3, 4, 12, 3, 64, 17, 2, 1, 2, 2, 2, 4, 64, 64, 64, 4, 4, 64, 64, 64, 11, 64],
         "height":10,
         "id":1,
         "name":"tiles",
         "opacity":1,
         "type":"tilelayer",
         "visible":true,
         "width":10,
         "x":0,
         "y":0
        }],
 "nextlayerid":2,
 "nextobjectid":1,
 "orientation":"orthogonal",
 "renderorder":"right-down",
 "tiledversion":"1.6.0",
 "tileheight":32,
 "tilesets":[
        {
         "firstgid":1,
         "source":"tileset.tsx"
        }],
 "tilewidth":32,
 "type":"map",
 "version":"1.6",
 "width":10
}